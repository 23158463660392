
import Vue from 'vue';

import ApiError from 'Entities/ApiError';
import Transfer from 'Entities/privatePresenter/Transfer';
import PublicDataApi from 'Apis/PublicData';
import BankRequisitesRequest from 'Entities/publicPresenter/BankRequisitesRequest';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import WalletsApi from 'Apis/Wallets';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import BankRequisites from 'Entities/publicPresenter/BankRequisites';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

type Data = {
    previousRoute: string;
    historyData: {
        assetSymbol: string;
        blockchainName: string;
        createdAt: string;
        id: string;
        quantity: number;
        bankRequisiteAlias: string;
        fee: {
            amount: number;
            assetSymbol: string;
        };
    };
    showWhitelistedWalletDetails: boolean;
    requisites: null | BankRequisites;
};

type Methods = {
    openLink: (data: string) => void;
    toggleDetails: () => void;
    goBack: () => void;
    setModalData: (data: any) => void;
    copy: (text: string, fieldName: string) => void;
    cancelTransferAndHideModal: () => void;
};

type Computed = {
    currentUser: InternalUserResponse | undefined;
    infoText: string;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        AssetValue,
        Button,
        Icon,
    },
    data() {
        return {
            previousRoute: '/wallets',
            historyData: {
                assetSymbol: '',
                blockchainName: '',
                createdAt: '',
                id: '',
                quantity: 0,
                bankRequisiteAlias: '',
                fee: {
                    amount: 0,
                    assetSymbol: '',
                },
            },
            showWhitelistedWalletDetails: true,
            requisites: null,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        infoText() {
            if (this.currentUser?.type === 'CORPORATE') {
                return `
                    <span>
                        Fiat deposits must originate only from your company's
                        <br />
                        whitelisted corporate bank accounts.
                        <br />
                        Deposits from third-party or non-whitelisted accounts will be rejected.
                    </span>
                `;
            }
            return `
                <span>
                    Deposits must originate only from your personal, whitelisted bank account.
                    <br />
                    Payments from third-party or non-whitelisted accounts will be rejected.
                </span>
            `;
        },
    },
    methods: {
        openLink(link) {
            window.open(link);
        },
        toggleDetails() {
            this.showWhitelistedWalletDetails = !this.showWhitelistedWalletDetails;
        },
        async goBack() {
            if (this.previousRoute) {
                await this.$router.push(this.previousRoute).catch(() => { /* navigation error */ });
                return;
            }
            this.$router.go(-1);
        },
        async setModalData(e) {
            try {
                this.historyData.assetSymbol = e.assetSymbol;
                this.historyData.blockchainName = e.blockchainName;
                this.historyData.createdAt = e.createdAt;
                this.historyData.id = e.id;
                this.historyData.quantity = e.quantity;
                this.historyData.bankRequisiteAlias = e.bankRequisiteAlias;
                if (e.commission && e.commission?.length > 0) {
                    this.historyData.fee.amount = e.commission[0].value;
                    this.historyData.fee.assetSymbol = e.commission[0].assetSymbol;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during setting modal data');
                }
            }
        },
        copy(text, fieldName) {
            if (text && fieldName) {
                copyTextWithPermissions(String(text), this.$store.dispatch, fieldName);
            }
        },
        async cancelTransferAndHideModal() {
            if (!this.historyData.id) {
                return;
            }

            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: this.historyData.id,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Deposit has been successfully canceled');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.goBack();
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
    async mounted() {
        await this.setModalData(new Transfer(this.$route.query as any));
        const { previousRoute } = this.$route.query;
        if (typeof previousRoute === 'string') {
            this.previousRoute = previousRoute;
        }

        try {
            const { data: requisites } = await PublicDataApi.publicGetBankRequisites(new BankRequisitesRequest({
                assetSymbol: this.historyData.assetSymbol,
                providerName: this.historyData.blockchainName,
            }));
            this.requisites = requisites;
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting bank requisites');
            }
        }
    },
});
